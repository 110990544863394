// core
.p-fluid .p-icon-field-left,
.p-fluid .p-icon-field-right {
    width: 100%;
}

.p-icon-field-left > .p-input-icon:first-of-type {
    left: nth($inputPadding, 2);
    color: $inputIconColor;
}

.p-icon-field-right > .p-input-icon:last-of-type  {
    right: nth($inputPadding, 2);
    color: $inputIconColor;
}
