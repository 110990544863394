// core
.p-icon-field {
    position: relative;
}

.p-icon-field > .p-input-icon {
    position: absolute;
    top: 50%;
    margin-top: -.5rem;
}
