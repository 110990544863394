.p-dropdown-trigger {
    width: unset;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
}
.localeSelector .p-autocomplete-token-icon {
    display: none;
}
.localeSelector .p-autocomplete-token {
    background: transparent;
}

.localeSelector .p-button {
    background-color: #eceff4;
    border-color: #eceff4;
    color: #3e3e3e;
}
